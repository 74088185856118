//WILL MATCH 
//http://localhost:8000/vqs/anything/anything
import React, { useState, useEffect } from 'react';
import { Link, navigate } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import ReactPaginate from 'react-paginate';
import "styles/vqs.style.css";
let json_current = require("assets/vqs.json");


function checkAnswer(option, correctAnswer) {
  if (option === correctAnswer) {
    console.log("Correct");
    return true;
  } else {
    console.log("Incorrect");
    return false;
  }
}

function Pagination(){
  return( <div className="columns is-centered">
  <div className="column is-12-mobile is-10-tablet is-8-desktop">
    <hr className="medangle-gradient-hairline" style={{ margin: 0 }} />
    <br />
    <div role="navigation" aria-label="Pagination" className="pagination">
      <a
        className="previous_page"
        rel="prev"
        href="/vqs/tagged/endocrinology,biochemistry?page=1"
      >
        ← Previous
      </a>{" "}
      <a
        rel="prev"
        aria-label="Page 1"
        href="/vqs/tagged/endocrinology,biochemistry?page=1"
      >
        1
      </a>{" "}
      <em className="current" aria-label="Page 2" aria-current="page">
        2
      </em>{" "}
      <a
        rel="next"
        aria-label="Page 3"
        href="/vqs/tagged/endocrinology,biochemistry?page=3"
      >
        3
      </a>{" "}
      <a
        aria-label="Page 4"
        href="/vqs/tagged/endocrinology,biochemistry?page=4"
      >
        4
      </a>{" "}
      <a
        aria-label="Page 5"
        href="/vqs/tagged/endocrinology,biochemistry?page=5"
      >
        5
      </a>{" "}
      <a
        aria-label="Page 6"
        href="/vqs/tagged/endocrinology,biochemistry?page=6"
      >
        6
      </a>{" "}
      <a
        aria-label="Page 7"
        href="/vqs/tagged/endocrinology,biochemistry?page=7"
      >
        7
      </a>{" "}
      <a
        aria-label="Page 8"
        href="/vqs/tagged/endocrinology,biochemistry?page=8"
      >
        8
      </a>{" "}
      <a
        aria-label="Page 9"
        href="/vqs/tagged/endocrinology,biochemistry?page=9"
      >
        9
      </a>{" "}
      <span className="gap">…</span>{" "}
      <a
        aria-label="Page 17"
        href="/vqs/tagged/endocrinology,biochemistry?page=17"
      >
        17
      </a>{" "}
      <a
        aria-label="Page 18"
        href="/vqs/tagged/endocrinology,biochemistry?page=18"
      >
        18
      </a>{" "}
      <a
        className="next_page"
        rel="next"
        href="/vqs/tagged/endocrinology,biochemistry?page=3"
      >
        Next →
      </a>
    </div>
    <center>
      Muneeb Akhter, you're on question 2 of <b>18</b>
    </center>
    <progress className="progress is-link" value={2} max={18}>
      {" "}
      Progress{" "}
    </progress>
  </div>
</div>);
}

function VerbalQuestion (props) {
const [isActive, setActive] = useState(false);
const [ToggleShowHide, setToggleShowHide] = useState(false);


  const toggleClass = () => {
    setActive(!isActive);
  };
  function toggleExplanationShowHide(){
    setToggleShowHide(!ToggleShowHide);
    //console.log("ToggleShowHide:" + ToggleShowHide)
   // console.log("className_explanation_show_hide:" + className_explanation_show_hide)
  }
 
  function HandlePageChange_reset_toggle(data){
    resetALLToggle()
    props.HandlePaginationChange(data)
    //    InitOptionTracker()
  }
  function resetALLToggle(){
    //toggleExplanationShowHide()
    setToggleShowHide(false);

  }
  var className_explanation_show_hide = ToggleShowHide ? 'vq-explanation explanation-show' : 'vq-explanation explanation-hide';
return(
  <body>
  <div className="columns is-multiline is-centered padding-xs">
  <div className="column is-12-mobile is-10-tablet is-8-desktop">
    <div className="gr-tags-container">
      <p className="global-text has-text-weight-bold">
        <span className="icon is-medium is-black-text">
          <i className="fas fa-tags" />
        </span>
        {props.Tags}
      </p>{" "}
      <h2 className="decorated has-text-weight-bold global-text">
        <span className="is-size-7">Muneeb Akhter</span>
      </h2>
    </div>
    <p className="vq-question is-size-3-desktop is-size-5-tablet is-size-6-mobile has-text-weight-bold">
      {props.Question}
    </p>
    <br />
    <input
      type="button"
      className="button is-info has-text-weight-bold is-normal"
      id="hideshow"
      defaultValue="Show / Hide"
      onClick={toggleExplanationShowHide}
    />
    <div className={className_explanation_show_hide}>
      <p>
      {props.Explanation}
      </p>
     
    </div>
  </div>
  <div class="column is-12-mobile is-8-desktop">
<p class="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-grey-lighter has-text-weight-bold">VQ generated for
Muneeb Akhter on
2021-08-06 16:59:51 +0000
</p>
</div>
</div>

    <div className="columns is-centered">
  <div className="column is-12-mobile is-10-tablet is-8-desktop">
  <hr className="medangle-gradient-hairline" style={{ margin: 0 }} />
<br/>
<ReactPaginate
                     marginPagesDisplayed={2}
                     pageRangeDisplayed={5}
                     pageCount={props.totalQuestions}
                     nextLabel={"Next →"}
                     breakClassName={"gap"}
                     previousLabel={"← Previous"}
                     containerClassName={"pagination"}
                     activeClassName={"current"}
                     onPageChange={HandlePageChange_reset_toggle}

                    />
  <center>
  Muneeb Akhter, you're on question {props.currentQuestion} of <b>{props.totalQuestions}</b>
    </center>
    <progress className="progress  is-info" value={props.currentQuestion} max={props.totalQuestions}>
      {" "}
      Progress{" "}
    </progress>
    </div>
    </div>
</body>
);
          }
VerbalQuestion.defaultProps = {
  Explanation:"Secondary causes of hypothyroidism are those that do not arise from intrinsic abnormalities from the thyroid gland itself. These include pituitary failures and hypothalamic failures.",
  Question:"What are the causes of secondary hypothyroidism?",
  Tags: "endocrinology pathology",
  totalQuestions: 27,
  currentQuestion: 2
};




function VerbalQuestionPage(props) {
  
  const [TagFound,setTagFound] = useState(false);
    const [isActive, setActive] = useState(false);

    //props
    const [current_url_page_param, setcurrent_url_page_param] = useState(false);
    const [json_index, setJSONindex] = useState(0);

    const [last_question, setLastQuestion] = useState(false);
    const [first_question, setFirstQuestion] = useState(false);

    const [json_data, setJSONdata] = useState(json_current);

   

   

  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(()=>{
   FilterJSONfoTopic();
   


    setMCQbasedonPageParam();
  }, [])

  function FilterJSONfoTopic(){
    var topic_to_filter = props.params.topic;
    const filtered_json_data = json_data.filter(question => question["VQ_Topic"]==topic_to_filter);
    console.log(filtered_json_data.length);
    if(filtered_json_data.length === 0){
      setTagFound(false)
     // console.log("BITXCH YOU")
    }
    else{
     // console.log("TH HELL YOU")
      setTagFound(true)
      setJSONdata(filtered_json_data)
    }
    

  }

function setMCQbasedonPageParam(){
  var params = new URLSearchParams(props.location.search);
    if(params.has("page") === false)
    {
    } 
    else{
      var requiredQuestion = params.get("page");
    
        setJSONindex(requiredQuestion-1);
      
      
    }

}
  function nextMCQ (){
    var params = new URLSearchParams(props.location.search);
    if(params.has("page") === false)
    {
      if(json_index === 0)
      {
        setJSONindex(json_index+1);
      }
      else if(json_index=== (json_data.length - 1))//last question require
      {
       // FixJSONOptioninjson_data(json_index+1)
      //  setJSONindex(json_index+1);
       
      }
      else{
        setJSONindex(json_index+1);
        //console.log("json_index: "+json_index);
      }
    } 
    else{
      var requiredQuestion = params.get("page");
      if(requiredQuestion >> json_data.length || requiredQuestion << 0 )//some one is shit // 
      {
        setJSONindex(json_index+1);
      }
      else{
        setJSONindex(requiredQuestion-1);//changed by useEFFECT
        //setcurrent_url_page_param(requiredQuestion-1)
      }
      
    }
  }
  function previousMCQ(){
    var params = new URLSearchParams(props.location.search);
    if(!params.has("page"))
    {
      if(json_index === 0) //already first mcq
      {
      
      }
      else{
       
        setJSONindex(json_index-1);
      }
    } 
    else{
      var requiredQuestion = params.get("page");
      if(requiredQuestion >> json_data.length || requiredQuestion << 0 )//some one is shit // 
      {
      
        setJSONindex(json_index-1);
      }
      else{
       
        setJSONindex(requiredQuestion-1);//changed by useEFFECT
        //setcurrent_url_page_param(requiredQuestion-1)
      }
      
    }
  }

  function ManualChangeJSONIndex(index_to_change_to){
    setJSONindex(index_to_change_to)
  }

function HandlePaginationChange(data){
  //data={selected:x}
  //where x is number which is selected
console.log(data)
ManualChangeJSONIndex(data.selected)
}

  function getURLparam(urlparamname = "page") {
    var params = new URLSearchParams(props.location.search);
    if (params.has(urlparamname)) {
      return params.get(urlparamname);
    }
    else { return false }
  }
  //egpath => /smart-sets/anymodule/anytopic
  //props.params => contain => props.params.module and props.params.topic
  //props.params.module = anymodule  (how => /smart-sets/* => /smart-sets/anymodule)
  //props.params.topic = anytopic (how  => /smart-sets/*/* => /smart-sets/anymodule/anytopic)
  //props.location.search = for => /smart-sets/module?param
  if(!TagFound){
    return(<Layout>
      <Seo title="Verbal Questions" />
      <div>
             <h1>
               No Tagged Found for {props.params.topic}
             </h1>
      </div>
  </Layout>)
  }
  else{
   
   return (
    <Layout>
        <Seo title="Verbal Questions" />
        <div>
            
            <VerbalQuestion
                Tags={json_data[json_index].Tags}
                currentQuestion={json_index+1}
                totalQuestions={json_data.length}
               Explanation={json_data[json_index].Explanation}
                Question={json_data[json_index].Question}
                previousMCQ={previousMCQ}
                nextMCQ={nextMCQ}
                HandlePaginationChange={HandlePaginationChange}
                
                />
               
        </div>
    </Layout>
)
  }
}

export default VerbalQuestionPage
